import { Container } from '@material-ui/core';
import Layout from '../../layout';
import React from 'react';

const Garantia = () =>(
    <Layout footer>
        <Container>
        <div style={{ height:'100vh' }}>
            <h1 className="title">GARANTÍA DE PRODUCTO</h1>
            <p><span>La garant&iacute;a es por un periodo de 1 a&ntilde;o desde el momento de la entrega.</span></p>
            <p><span>Se garantiza la conformidad del producto de acuerdo con el uso para el cual est&aacute; destinado.</span></p>
            <p><span>Dentro del periodo de garant&iacute;a subsanaremos cualquier defecto de fabricaci&oacute;n ya sea repara</span><span>n</span><span>do o sustituyendo la pieza, siempre que la opci&oacute;n escogida sea factible.</span></p>
            <p><span>La presente garant&iacute;a no ser&aacute; v&aacute;lida en los supuestos de:&nbsp;</span></p>
            <ul>
                <li><span>Mal uso</span></li>
                <li><span>Lavado inadecuado de las prendas</span></li>
                <li><span>Mala manipulaci&oacute;n de la ropa por parte del personal</span></li>
                <li><span>Por el deterioro de productos qu&iacute;micos para el lavado</span></li>
                <li><span>Secado de las prendas en temperaturas mayores a las adecuadas</span></li>
            </ul>
        </div>
        </Container>
    </Layout>
);

export default Garantia;